import { TYPE_OF_UPDATE_OPTIONS } from '@root/modules/dashboard/types';
import { ICanBeSwitchedToItem, IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { useMemo } from 'react';

export const useGetSubscriptionList = (item: IPurchasedProduct) => {
  return useMemo(() => {
    return (item.canBeSwitchedTo as ICanBeSwitchedToItem[]).reduce((acc, bundle) => {
      const key = item.bundleGroupId === bundle.bundleGroupId ? TYPE_OF_UPDATE_OPTIONS.RENEW : TYPE_OF_UPDATE_OPTIONS.UPGRADE;
      acc[key] ? acc[key].push(bundle) : (acc[key] = [bundle]);
      return acc;
    }, {} as Record<TYPE_OF_UPDATE_OPTIONS, ICanBeSwitchedToItem[]>);
  }, [item]);
};
