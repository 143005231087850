import type { CreateAddressDto } from '../dtos/create-address.dto';
import type { IAddress } from '../types/address';
import type { IPaymentAddress } from '../types/payment-item';

export class CreateAddressDtoMapper {
  public static toDomain(address: IAddress): CreateAddressDto {
    return {
      id: address.id.toString(),
      firstname: address.firstname,
      lastname: address.lastname,
      country: address.country,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      firstAddressLine: address.firstAddressLine,
      secondAddressLine: address.secondAddressLine || '',
      phoneNumberPrefix: address.phoneNumberPrefix,
      phoneNumber: address.phoneNumber,
      isDefault: address.isDefault,
      isSyncWithUserAddress: false,
      shouldHaveState: false,
    };
  }

  public static toPersistence(address: CreateAddressDto) {
    return {
      country: address.country,
      city: address.city,
      address: address.firstAddressLine,
      address2: address.secondAddressLine,
      state: address.state,
      zipCode: address.postalCode,
      primary: address.isDefault,
      phone: '+' + `${address.phoneNumberPrefix.replace(/\D/g, '')}${address.phoneNumber}`.replace(/\+/g, ''),
      firstName: address.firstname,
      lastName: address.lastname,
      addressId: address?.id ? +address.id : undefined,
    };
  }

  public static toPaymentAddress(address: CreateAddressDto): IPaymentAddress {
    return {
      firstname: address.firstname,
      lastname: address.lastname,
      firstAddressLine: address.firstAddressLine,
      secondAddressLine: address.secondAddressLine,
      postalCode: address.postalCode,
      city: address.city,
      country: address.country,
      phoneNumbers: [address.phoneNumberPrefix + address.phoneNumber],
    };
  }
}
