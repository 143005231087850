import { canAddNotificationChecker as canBeAddedChecker } from '../helpers/can-add-notification-checker';
import { createNotification } from '../helpers/create-notification';
import { useNotifications } from '../hooks/useNotifications';
import { INotification } from '../types/notification';
import { useRedirection } from '@root/modules/auth/hooks/use-redirection';
import { authSelector } from '@root/modules/auth/redux';
import { REDIRECTION_TYPES } from '@root/modules/auth/types';
import { selectKycLevel1Required, selectKycLevel2Required } from '@root/modules/kyc/redux/kyc.selector';
import { selectIsCurrentMembershipMoreThanExpected } from '@root/modules/membership/redux/membership.selector';
import { BEFORE_EXPIRE_DAYS } from '@root/modules/products/constants/before-expires-days';
import { purchasedProductsSelector } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { Id } from '@root/shared/constants';
import { pageLinks } from '@root/shared/routing';
import { differenceInDays } from 'date-fns';
import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';

export const useNotificationsChecker = () => {
  const { t } = useTranslation('notifications');
  const navigate = useNavigate();
  const { redirect } = useRedirection();

  const [{ notifications }, { addNotification, removeNotification }] = useNotifications();

  const { user } = useSelector(authSelector);
  const { data: subscriptions } = useSelector(purchasedProductsSelector);
  const isKycLevel1Required = useSelector(selectKycLevel1Required);
  const isKycLevel2Required = useSelector(selectKycLevel2Required);
  const isPaymentCardExpired = user?.isPaymentCardExpired;
  const isCurrentMembershipMoreThanExpected = useSelector(selectIsCurrentMembershipMoreThanExpected);
  const expiredSubscriptions = subscriptions.filter((item) => item.isExpired);
  const subscriptionsExpiresSoon = subscriptions.filter((item) => !item.isExpired && differenceInDays(new Date(item.expireAt), Date.now()) <= BEFORE_EXPIRE_DAYS);

  const handleNotification = useCallback(
    (id: Id, item: Omit<INotification, 'id'>) => {
      const notification = createNotification(id, item);

      if (canBeAddedChecker(notification, notifications)) {
        addNotification(notification);
      }
    },
    [notifications, addNotification],
  );

  const checkNotifications = useCallback(() => {
    if (!user) {
      return;
    }

    if (!!expiredSubscriptions.length) {
      expiredSubscriptions.map((item, index) =>
        handleNotification(`${user.customerId}_${index}`, {
          color: 'danger',
          type: 'subscription-expired',
          textData: {
            key: 'expired_subscription.text',
            options: { user: user.fullName, item: item.title.text },
          },
          buttonText: 'expired_subscription.button-text',
          buttonAction: () => redirect(REDIRECTION_TYPES.RENEW, { planId: item.id }),
        }),
      );
    }

    if (!!subscriptionsExpiresSoon.length) {
      subscriptionsExpiresSoon.map((item, index) => {
        const now = dayjs();
        const expireTime = dayjs(item.expireAt);
        const count = expireTime.startOf('day').diff(now.startOf('day'), 'day') || now.isBefore(expireTime) ? 1 : 0;
        const text = count === 1 ? 'expires_in_day.text' : 'expires_in_days.text';

        return handleNotification(`${user.customerId}_${index}`, {
          color: 'warning',
          type: 'subscription-expires',
          textData: {
            key: text,
            options: {
              user: user.fullName,
              count,
            },
          },
          buttonText: 'expires_in_days.button-text',
          buttonAction: () => redirect(REDIRECTION_TYPES.RENEW, { planId: item.id }),
        });
      });
    }

    if (isKycLevel1Required) {
      handleNotification(`${user.customerId}`, {
        color: 'warning',
        type: 'kyc-1-required',
        textData: {
          key: 'kyc_1_required.text',
          options: { user: user.fullName },
        },
        buttonText: 'kyc_1_required.button-text',
        buttonAction: () => navigate(pageLinks.kycIdentityVerification),
      });
    }

    if (isKycLevel2Required) {
      handleNotification(`${user.customerId}`, {
        color: 'warning',
        type: 'kyc-2-required',
        textData: {
          key: 'kyc_2_required.text',
          options: { user: user.fullName },
        },
        buttonText: 'kyc_2_required.button-text',
        buttonAction: () => navigate(pageLinks.kycAddressVerification),
      });
    }

    if (isPaymentCardExpired) {
      handleNotification(`${user.customerId}`, {
        color: 'danger',
        type: 'payment-card-expired',
        textData: {
          key: 'payment_card_expired.text',
          options: { user: user.fullName },
        },
        buttonText: 'payment_card_expired.button-text',
        buttonAction: () => navigate(pageLinks.paymentMethods),
      });
    }

    if (isCurrentMembershipMoreThanExpected) {
      handleNotification(`${user.customerId}`, {
        color: 'info',
        type: 'higher_membership_level',
        textData: {
          key: 'higher_membership_level.text',
          options: { user: user.fullName },
        },
        buttonText: 'higher_membership_level.button-text',
        buttonAction: () =>
          navigate({
            pathname: '/',
            search: createSearchParams({
              widget_state: 'maximized',
            }).toString(),
          }),
      });
    }
  }, [
    isCurrentMembershipMoreThanExpected,
    handleNotification,
    t,
    redirect,
    navigate,
    user,
    expiredSubscriptions,
    subscriptionsExpiresSoon,
    isKycLevel1Required,
    isKycLevel2Required,
  ]);

  useEffect(() => {
    checkNotifications();
  }, [user, subscriptions, checkNotifications]);

  const state = { notifications };
  const handlers = { removeNotification };

  return [state, handlers] as [typeof state, typeof handlers];
};
