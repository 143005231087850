import { useRedirection } from '@root/modules/auth/hooks/use-redirection';
import { REDIRECTION_TYPES } from '@root/modules/auth/types';
import { TYPE_OF_UPDATE_OPTIONS } from '@root/modules/dashboard/types';
import { selectRedirectInstanceTypeByUserSubscription } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { ICanBeSwitchedToItem, IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { UpgradeSubscriptionModal } from '@root/modules/subscriptions/components/modals/upgrade-subscription';
import { subscriptionsSelector } from '@root/modules/subscriptions/redux/subscriptions.selector';
import { useGetSubscriptionList } from "@root/shared/hooks/use-get-subscription-list";
import { Button } from '@root/shared/ui';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


export const UpgradeOptions: FC<{ item: IPurchasedProduct }> = ({ item }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'bundleInfo' });
  const { redirect } = useRedirection();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [typeOfOptions, setTypeOfOptions] = useState<TYPE_OF_UPDATE_OPTIONS | null>(null);
  const { activeUpgrade } = useSelector(subscriptionsSelector);
  const { isAnnual } = useSelector(selectRedirectInstanceTypeByUserSubscription);

  const openModal = useCallback((typeOfOptions: TYPE_OF_UPDATE_OPTIONS) => {
    setTypeOfOptions(typeOfOptions);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setTypeOfOptions(null);
    setIsModalOpen(false);
  }, []);

  const handleConfirmUpgrade = useCallback(() => {
    closeModal();
    redirect(REDIRECTION_TYPES.SWITCH_TO_PLAN, { planId: activeUpgrade?.planId, isAnnual });
  }, [closeModal, activeUpgrade, isAnnual, redirect]);

  const options = useGetSubscriptionList(item);

  if (!options.renew && !options.upgrade) {
    return null;
  }

  return (
    <div className='border-b border-gray-300 p-4'>
      {/*{options.length === 1 ? (*/}
      {/*  <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2'>*/}
      {/*    <div>*/}
      {/*      <BodyTextLarge className='font-bold'>*/}
      {/*        <PriceItem value={options[0].recurringPrice} type='long' />*/}
      {/*        <span className='text-xs'>/{options[0].recurringType === RECURRING_TYPES.MONTHLY ? t('recurring-types.monthly.month') : t('recurring-types.annual.year')}</span>*/}
      {/*      </BodyTextLarge>*/}
      {/*      {options[0].youSave > 0 && (*/}
      {/*        <BodyText className='text-success-500 font-bold'>*/}
      {/*          {t('you-save')}*/}
      {/*          <PriceItem value={options[0].youSave} type='long' />*/}
      {/*        </BodyText>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*    <Button className='w-full sm:w-auto py-2 px-4 text-base lg:text-sm lg:py-1.5' onClick={() => handleSingleUpgradeRedirect(options[0].planId)}>*/}
      {/*      {options[0].recurringType === RECURRING_TYPES.MONTHLY ? t('recurring-types.monthly.upgrade') : t('recurring-types.annual.upgrade')}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*) : (*/}
      <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between gap-5 flex-wrap'>
        {/* <BodyText className='font-bold mb-1 sm:mb-0'>{t('available-options')}</BodyText> */}
        {options.upgrade?.length > 0 && (
          <Button
            className='upgrade_subscription_button w-full sm:w-auto py-2 px-4 text-base lg:text-sm lg:py-1.5'
            color='primary'
            onClick={() => openModal(TYPE_OF_UPDATE_OPTIONS.UPGRADE)}
          >
            {t('upgrade_subscription')}
          </Button>
        )}
        {options.renew?.length > 0 && (
          <Button
            className='renewal_option_button w-full sm:w-auto py-2 px-4 text-base lg:text-sm lg:py-1.5'
            color='secondary'
            onClick={() => openModal(TYPE_OF_UPDATE_OPTIONS.RENEW)}
          >
            {t('renewal_options')}
          </Button>
        )}
      </div>
      {/*)}*/}
      {typeOfOptions && isModalOpen && (
        <UpgradeSubscriptionModal isOpen={true} close={closeModal} confirm={handleConfirmUpgrade} items={options[typeOfOptions]} typeOfOption={typeOfOptions} />
      )}
    </div>
  );
};
