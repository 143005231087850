import { ISeparateService } from '@root/modules/dashboard/types';
import { BodyTextLarge, Button, ButtonContainer, ModalComponent } from '@root/shared/ui';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const CancelSubscriptionModal: FC<{ close: () => void; onOk: () => void, item: ISeparateService }> = ({
  close,
  onOk,
  item,
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <ModalComponent className='md:max-w' headerTitle={t('subscriptions:cancel_subscription_modal.title')} modalIsOpen={true} closeModal={close}>
      <div>
        <div className='pt-8 pb-10 px-4 lg:px-6'>
          <Trans i18nKey='dashboard:bundleInfo.cancel_subscription_modal.description' values={{service: item.separatedServicesData.name }} components={{highlight: <span className="text-purple-600 font-bold"/>}}/>
        </div>

        <ButtonContainer withPadding className='flex justify-between !px-4 lg:px-10 sm:flex-row'>
          <Button className='w-full md:w-auto lg:self-center md:py-3 md:px-10 md:m-4' color='secondary' onClick={close}>
            {t('bundleInfo.cancel_subscription_modal.decline_button')}
          </Button>
          <Button className='w-full md:w-auto lg:self-center md:py-3 md:px-10 md:m-4' onClick={() => onOk()}>
            {t('bundleInfo.cancel_subscription_modal.agree_button')}
          </Button>
        </ButtonContainer>
      </div>
    </ModalComponent>
  );
};
