import { getPurchasedProducts } from '../../services/get-purchased-products';
import { all, call, delay, put, SagaReturnType, select, take, takeLatest } from '@redux-saga/core/effects';
import { RECURRING_TYPES } from '@root/infra/enums/reccuring-type';
import { authSlice } from '@root/modules/auth/redux';
import { productsListSelector } from '@root/modules/products/redux/products/products.selector';
import { productsSlice } from "@root/modules/products/redux/products/products.slice";
import { purchasedProductsSlice } from '@root/modules/products/redux/purchased-products/purchased-products.slice';
import { IPurchasedProduct } from '@root/modules/products/types/purchased-product';


function* handleFetch() {
  const result: SagaReturnType<typeof getPurchasedProducts> = yield call(getPurchasedProducts);
  
  if (result.status === 200) {
    let products = yield select(productsListSelector);
    let recurringType = '';

    if (products?.length) {
      const purchasedProducts = result.payload
        .map((item) => {
          const product = products?.find((product) => product.shopId === item.shopId);
          const isExpired = item.status === 'Expired' || new Date(item.expireAt) < new Date();

          if (item.isDigitalBundle) {
            recurringType = item.recurringType;
          }

          if (!product) {
            return {
              ...item,
              title: { text: item.title },
              canUpgrade: !!item.canBeSwitchedTo.length,
              isExpired: isExpired,
            };
          }

          return {
            ...item,
            id: product.id,
            isAddon: product.isAddon,
            isPhysicalAddon: product.isPhysicalAddon,
            isDigitalBundle: product.isDigitalBundle,
            isPhysicalBundle: product.isPhysicalBundle,
            urlParam: product.urlParam,
            title: product.title,
            imageUrl: product.imageUrl,
            canUpgrade: !!item.canBeSwitchedTo.length,
            isExpired,
            rebillySubscriptionId: item.rebillySubscriptionId,
            renewalOptions: product.renewalOptions,
            inactiveServices: item.inactiveServices || [],
          };
        })
        .filter((item) => !!item) as IPurchasedProduct[];
        
        yield put(authSlice.actions.setUserRecurringType(recurringType as RECURRING_TYPES));
        yield put(purchasedProductsSlice.actions.fetchFulfilled(purchasedProducts));
        return;
      }
      
      yield put(purchasedProductsSlice.actions.fetchFulfilled(result.payload));
  } else {
    yield put(purchasedProductsSlice.actions.fetchRejected(result.payload));
  }
}

function* handleClear() {
  while (true) {
    yield take(authSlice.actions.loggedOut);
    yield put(purchasedProductsSlice.actions.reset());
  }
}

export function* purchasedProductsFlowSaga() {
  yield takeLatest([productsSlice.actions.fetchFulfilled.type], handleFetch);
  yield all([handleClear()]);
}
