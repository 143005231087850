import { ICanBeSwitchedToItem } from "@root/modules/products/types/purchased-product";

export enum TYPE_OF_UPDATE_OPTIONS {
	UPGRADE = 'upgrade',
	RENEW = 'renew'
}

export interface IUpdatePlanOptions {
  upgrade: ICanBeSwitchedToItem[];
}
