import { DashboardUI } from '../components/dashboard.ui';
import { AvailableProducts } from '../containers/available-products';
import { BundleInfo } from '../containers/bundle-info';
import { MembershipInfo } from '../containers/membership-info';
import { PaymentMethodsInfo } from '../containers/payment-methods-info';
import { QuickActions } from '../containers/quick-actions';
import { Statistics } from '../containers/statistics';
import { SeparateServiceInfo } from '@root/modules/dashboard/containers/separate-service-info';
import { Notifications } from '@root/modules/notifications/containers/notifications';
import { purchasedBundleSelector } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { H1 } from '@root/shared/ui';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const DashboardPage = () => {
  const { t } = useTranslation('dashboard');

  const digitalBundles = useSelector(purchasedBundleSelector);
  const amountCardsOnPage = digitalBundles.length > 1 ? 2 : 3;
  const sortByDigitalBundle = [...digitalBundles].sort((a, b) => Number(b.isDigitalBundle) - Number(a.isDigitalBundle));

  return (
    <DashboardUI.Page>
      <div className='flex flex-col lg:flex-col-reverse px-5 lg:px-0 pt-6 lg:pt-0'>
        <H1 className='text-[28px] font-bold mb-4 lg:mb-4 lg:hidden'>{t('title')}</H1>

        <div className='mb-4 lg:px-12 lg:mt-4 lg:mb-0'>
          <Notifications />
        </div>

        <div className={`flex flex-col gap-4 lg:gap-0 lg:grid lg:grid-cols-${amountCardsOnPage}`}>
          {!!digitalBundles.length ? (
            sortByDigitalBundle.map((item) => {
              const services = item.services.filter((service) => service.isSeparateRenewal);
              return (
                <Fragment key={item.id}>
                  <BundleInfo bundleItem={item} />
                  {services.map((service) => (
                    <SeparateServiceInfo key={service.id} separatedServicesData={service} planId={item.subscriptionId} expireAt={item.expireAt} />
                  ))}
                  {item.inactiveServices.map((inactiveService) => (
                    <SeparateServiceInfo inactive separatedServicesData={inactiveService} planId={item.subscriptionId} expireAt={item.expireAt} />
                  ))}
                </Fragment>
              );
            })
          ) : (
            <BundleInfo bundleItem={null} />
          )}
          <PaymentMethodsInfo />
          <MembershipInfo />
        </div>
      </div>

      <Statistics />
      <QuickActions />
      <AvailableProducts />
    </DashboardUI.Page>
  );
};
