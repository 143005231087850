import { IPurchasedProduct } from '../types/purchased-product';

export class PurchasedProductMapper {
  public static toDomain(obj): IPurchasedProduct {
    return {
      id: obj.planId,
      expireAt: obj.expireAt,
      shopId: obj.planId,
      status: obj.status === 'Expired' ? 'Expired' : 'Active',
      autopayable: obj.autopayable,
      autopay: obj.autopay,
      isAddon: obj.isAddon,
      isDigitalBundle: obj.isDigitalBundle,
      isPhysicalAddon: obj.isPhysicalAddon,
      isPhysicalBundle: obj.isPhysicalBundle,
      urlParam: obj.urlParam,
      imageUrl: obj.imageUrl,
      title: obj.level,
      services: obj.services,
      recurringPrice: obj.recurringPrice,
      setupPrice: obj.setupPrice,
      subtitle: obj.subtitle,
      canBeSwitchedTo: Array.isArray(obj.canBeSwitchedTo) ? obj.canBeSwitchedTo.filter((item) => !!item) : [],
      recurringType: obj.recurringType,
      rebillySubscriptionId: obj.rebillySubscriptionId,
      renewalOptions: obj.renewalOptions || [1],
      bundleGroupId: obj.bundleGroupId,
      inactiveServices: obj.inactiveServices || [],
      subscriptionId: obj.id
    };
  }
}
