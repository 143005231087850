import Modal from 'react-modal';
import styled from 'styled-components';
import { IconButton } from './icon-button';
import { ReactNode } from 'react';

export const customStyles = {
  overlay: {
    backgroundColor: 'rgba(15,18,23,0.6)',
    zIndex: 1030,
  },
};

export interface StyledModalExtraProps {
  fullWidthOnMobile?: boolean;
  fullHeightOnMobile?: boolean;
  headerTitle?: ReactNode;
  visible?: boolean;
  hideFooter?: boolean;
  withHeader?: boolean;
  padding?: number;
}

export const StyledModal = styled(Modal)<StyledModalExtraProps>`
  overflow: hidden;
  position: absolute;
  padding: ${({ theme, withHeader, padding }) => (!padding && padding !== 0 ? (withHeader ? 0 : `${theme.spacing(4)}px`) : padding)};
  box-shadow: 0 10px 24px 0 rgba(12, 12, 12, 0.6);
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.gray[100]};
  min-width: 300px;
  outline: none;
  width: ${({ fullWidthOnMobile }) => (fullWidthOnMobile ? '100%' : null)};
  max-width: ${({ fullWidthOnMobile }) => (fullWidthOnMobile ? '100%' : 'calc(100vw - 40px)')};
  overflow-y: ${({ visible }) => (visible ? 'visible' : 'auto')};
  max-height: ${({ fullHeightOnMobile }) => (fullHeightOnMobile ? '100vh' : '80vh')};
  z-index: 1030;
  height: ${({ fullHeightOnMobile }) => (fullHeightOnMobile ? '100vh' : 'auto')};

  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  @media (min-width: 768px) {
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 40px);
    min-height: initial;
    width: auto;
    padding: ${({ theme, withHeader, padding }) => (padding === undefined ? (withHeader ? 0 : `${theme.spacing(10)}px`) : padding)};
    padding-top: ${({ theme, withHeader, padding }) => (padding === undefined ? (withHeader ? 0 : `${theme.spacing(6)}px`) : padding)};
  }

  @media (min-width: 994px) {
    width: 650px;
  }

  .header {
    text-align: center;
    border-radius: 6px 6px 0 0;
    background: ${({ theme }) => theme.colors.gradients['9']};
    padding: ${({ theme }) => `${theme.spacing(6)}px 0`};
  }

  .footer {
    margin-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray['300']};
    padding: ${({ theme }) => `${theme.spacing(6)}px 0 ${theme.spacing(6)}px `} 0;
  }

  &.responsive-modal {
    .modal-content {
      position: relative;
      max-height: ${({ headerTitle }) => (headerTitle ? 'calc( 100vh - 96px - 40px - 65px)' : 'calc( 100vh - 40px - 65px)')};
      overflow-y: ${({ visible }) => (visible ? 'visible' : 'auto')};
      padding-bottom: ${({ hideFooter }) => (hideFooter ? '0' : '82px')};
      .button-container {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }
`;

StyledModal.defaultProps = {
  fullWidthOnMobile: false,
};

export const CloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.gray[100]};
  font-size: 16px;
  border: none;
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.gray['900']};
  z-index: 1;
  border-radius: ${({ theme }) => `${theme.spacing(2)}px`};

  &:before {
    background: transparent;
  }

  &:hover {
    border: none;
    color: ${({ theme }) => theme.colors.success[500]};
    background: transparent;
  }
`;
