import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from "@root/shared/utils/handle-axios-error";
import { AxiosError } from 'axios';


export type CancelSubscription = IHttpResponse<200, any> | IHttpResponse<400, string>;

interface ICancelSubscriptionPayload {
  subscriptionId: number;
  serviceId: number;
}

export const toggleSubscription = async (payload: ICancelSubscriptionPayload): Promise<CancelSubscription> => {
  const { subscriptionId, serviceId } = payload;
  try {
    const response = await fetcher.patch(`/subscriptions/${subscriptionId}/service/${serviceId}/toggle`);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
