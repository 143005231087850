import { IMembershipLevel } from '../types/membership-level';
import { IMembershipsState } from './membership.slice';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { AppState } from '@root/infra/redux';
import { IAuthState } from '@root/modules/auth/redux';
import { IProductsState } from '@root/modules/products/redux/products/products.slice';
import { PurchasedProductsState } from '@root/modules/products/redux/purchased-products/purchased-products.slice';

export const membershipSelector = createSelector<
  [Selector<AppState, IMembershipsState>, Selector<AppState, IAuthState>, Selector<AppState, IProductsState>],
  IMembershipsState['memberships']
>(
  (state) => state.membership,
  (state) => state.auth,
  (state) => state.products,
  (membership, auth, products) => {
    const isRoockiePresent = !!products.data?.find((item) => item.title.text.toLowerCase().includes('rookie'));
    const memberships = membership.memberships?.filter((item) => (isRoockiePresent ? item : !item.memberName.toLowerCase().includes('rookie')));

    if (!!auth.user?.membershipId && memberships?.length) {
      return memberships?.map((item) => ({ ...item, isCurrent: item.id === auth.user?.membershipId }));
    }
    return memberships || [];
  },
);

export const currentMembershipSelectorByUserLevel = createSelector<[Selector<AppState, IMembershipsState>, Selector<AppState, IAuthState>], IMembershipLevel | null>(
  (state) => state.membership,
  (state) => state.auth,
  (membership, auth) => {
    return membership.memberships?.find((m) => m?.id === auth.user?.membershipId) || null;
  },
);

export const selectIsCurrentMembershipMoreThanExpected = createSelector<
  [Selector<AppState, IMembershipsState>, Selector<AppState, IAuthState>, Selector<AppState, IProductsState>, Selector<AppState, PurchasedProductsState>],
  boolean
>(
  (state) => state.membership,
  (state) => state.auth,
  (state) => state.products,
  (state) => state.purchasedProducts,

  (membership, auth, products, purchasedProducts) => {
    try {
      const purchasedBundle = purchasedProducts.data.find((item) => item.isDigitalBundle);
      const setupPrice = products.data.find((item) => item.id === purchasedBundle?.id)?.pricing?.price?.amount || 0;
      const expectedMembershipId = typeof setupPrice === 'number' && membership.memberships.find((item) => item.min <= setupPrice && item.max >= setupPrice)?.id;
      const realMembershipId = auth?.user?.membershipId;

      if (expectedMembershipId && typeof expectedMembershipId === 'number' && realMembershipId) {
        return realMembershipId > expectedMembershipId;
      }
      return false;
    } catch (e) {
      console.log('e', e);
      return false;
    }
  },
);
