import { ISeparateService } from '@root/modules/dashboard/types';
import { productsSlice } from '@root/modules/products/redux/products/products.slice';
import { availableProductsSelector, purchasedProductsSelector } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { toggleSubscription } from '@root/modules/subscriptions/services/cancel-subscription.service';
import { notify } from '@root/shared/utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useServiceInfo = (serviceItem: ISeparateService) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
  const { isLoading: isPurchasesLoading, loaded: isPurchasesLoaded } = useSelector(purchasedProductsSelector);
  const { isLoading: isProductsLoading } = useSelector(availableProductsSelector);

  const isLoading = isPurchasesLoading || !isPurchasesLoaded;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCancelSubscription = async () => {
    try {
      setIsModalOpen(false);
      setButtonIsLoading(true);
      const response = await toggleSubscription({ subscriptionId: serviceItem.planId, serviceId: serviceItem.separatedServicesData.id });

      if (response.status === 200) {
        dispatch(productsSlice.actions.fetchPending());
      } else {
        notify({ type: 'danger', title: response.payload });
        setButtonIsLoading(false);
      }
    } finally {
      if (isProductsLoading) setButtonIsLoading(false);
    }
  };

  const handleEnableSubscription = async () => {
    try {
      setButtonIsLoading(true);
      const response = await toggleSubscription({ subscriptionId: serviceItem.planId, serviceId: serviceItem.separatedServicesData.id });

      if (response.status === 200) {
        dispatch(productsSlice.actions.fetchPending());
      } else {
        notify({ type: 'danger', title: response.payload });
        setButtonIsLoading(false);
      }
    } finally {
      if (isProductsLoading) setButtonIsLoading(false);
    }
  };

  const state = {
    isModalOpen,
    isLoading,
    buttonIsLoading,
  };

  const handlers = {
    openModal,
    closeModal,
    handleCancelSubscription,
    handleEnableSubscription,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
